
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        stage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            default: 3
        }
    }
});
