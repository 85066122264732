<template>
    <div class="subscription">
        <transition name="card">
            <Welcome
                v-if="stage === 0"
                class="card"
                @next="stage++"
            />
        </transition>
        <transition name="card">
            <SubscriptionName
                v-if="stage === 1"
                v-model="name"
                class="card"
                @next="stage++"
            />
        </transition>
        <transition name="card">
            <SubscriptionMail
                v-if="stage === 2"
                v-model="mail"
                class="card"
                @next="stage++"
            />
        </transition>
        <transition name="card">
            <SubscriptionPassword
                v-if="stage === 3"
                v-model="password"
                class="card"
                @next="subscribe"
            />
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SubscriptionMail from "@/components/subscribe/stages/SubscriptionMail.vue";
import SubscriptionName from "@/components/subscribe/stages/SubscriptionName.vue";
import SubscriptionPassword from "@/components/subscribe/stages/SubscriptionPassword.vue";
import Welcome from "@/components/subscribe/stages/Welcome.vue";
import PharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        SubscriptionMail,
        SubscriptionName,
        SubscriptionPassword,
        Welcome
    },
    data () {
        return {
            stage: 0,
            name: "",
            mail: "",
            password: ""
        };
    },
    methods: {
        async subscribe (): Promise<void> {
            await PharmacyStore.register({
                name: this.name,
                mail: this.mail,
                password: this.password
            });
            if (await PharmacyStore.isConnected()) {
                this.$router.push({ name: "home" });
            }
        }
    }
});
</script>

<style scoped lang="scss">
.subscription {
    width: 680px;
    height: 400px;
    position: relative;
}

.card {
    width: 680px;
    height: 400px;
    position: absolute;
    opacity: 1;
    transform: translateY(0);
    box-sizing: border-box;
}

.card-enter-active {
    transition: ease-out 0.2s;
}

.card-leave-active {
    transition: ease-in 0.2s;
}

.card-enter-from {
    opacity: 0;
    transform: translateY(100vh);
}

.card-leave-to {
    opacity: 0;
    transform: scale(0.8);
}
</style>
