
import { defineComponent } from "vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import Subscription from "@/components/subscribe/Subscription.vue";
import TopLogo from "@/components/template/TopLogo.vue";

export default defineComponent({
    components: {
        LoginLayout,
        Subscription,
        TopLogo
    }
});
