interface PromiseDebounceOptions {
    reject?: boolean;
    default?: unknown;
}

export function promiseDebounce (exec: (...args: any[]) => Promise<any>, interval: number, options?: PromiseDebounceOptions): () => ReturnType<typeof exec> {
    let handle: number | undefined;
    let resolves: Array<(value?: unknown) => void> = [];
    let rejects: Array<(reason?: any) => void> = [];

    return async (...args: unknown[]) => {
        if (handle && options?.reject) {
            if (options.default !== undefined) {
                resolves.forEach(resolve => resolve(options.default));
            }
            else {
                rejects.forEach(reject => reject());
            }

            resolves = [];
            rejects = [];
        }
        clearTimeout(handle);
        handle = setTimeout(
            () => {
                const result = exec(...args);
                resolves.forEach(resolve => resolve(result));
                resolves = [];
                rejects = [];
            },
            interval
        );

        return new Promise((resolve, reject) => {
            resolves.push(resolve);
            rejects.push(reject);
        });
    };
}
