<template>
    <StageLayout
        button="Commencer"
        @next="$emit('next')"
    >
        <h1>Bienvenue !</h1>
        <p>
            Nous sommes ravis de vous accueillir. Nous allons vous guider dans la configuration de votre compte, tout sera prêt dans deux minutes.
        </p>
    </StageLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StageLayout from "@/components/subscribe/stages/StageLayout.vue";

export default defineComponent({
    components: {
        StageLayout
    },
    emits: ["next"]
});
</script>

<style scoped lang="scss">

h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    color: var(--color-primary);
}

p {
    font-size: 26px;
}

.button {
    font-size: 26px;
    font-weight: 600;
}
</style>
