
import { defineComponent } from "vue";
import FormButton from "@/components/template/elements/FormButton.vue";
import Popup from "@/components/template/Popup.vue";
import StageDots from "@/components/subscribe/StageDots.vue";

export default defineComponent({
    components: {
        FormButton,
        Popup,
        StageDots
    },
    props: {
        button: {
            type: String,
            default: "Suivant"
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["next"]
});
