<template>
    <TopLogo />
    <LoginLayout class="layout">
        <Subscription />
    </LoginLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import Subscription from "@/components/subscribe/Subscription.vue";
import TopLogo from "@/components/template/TopLogo.vue";

export default defineComponent({
    components: {
        LoginLayout,
        Subscription,
        TopLogo
    }
});
</script>

<style scoped lang="scss">

.logo {
    display: block;
    width: 96px;
    margin: auto auto 16px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form-input {
    width: 400px;
}

.layout {
    overflow: hidden;
}
</style>
