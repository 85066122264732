
import { defineComponent } from "vue";
import * as Yup from "yup";
import { promiseDebounce } from "@/utils";

import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import StageLayout from "@/components/subscribe/stages/StageLayout.vue";
import ApiService from "@/services/api.service";

export default defineComponent({
    components: {
        ContentSpacer,
        FormInput,
        StageLayout
    },
    emits: ["next", "update:modelValue"],
    data () {
        const checkMail = async (mail: string): Promise<boolean> => {
            const response = await ApiService.get(`/pharmacy/checkMail/${mail}`) as {exist: boolean};
            return !response.exist;
        };

        const debounceCheckMail = promiseDebounce(checkMail, 500);

        return {
            rules: Yup.string()
                .required("Vous devez entrer un email.")
                .email("Vous devez entrer un email valide.")
                .test("check-mail", "Cet email est déjà utilisé.", debounceCheckMail),
            value: ""
        };
    },
    watch: {
        value () {
            this.$emit("update:modelValue", this.value);
        }
    },
    methods: {
        async checkForm (): Promise<void> {
            const isValid = await (this.$refs.input as typeof FormInput).checkValidity();

            if (isValid) {
                this.$emit("next");
            }
        }
    }
});
