<template>
    <Popup class="popup">
        <StageDots
            v-if="stage"
            :stage="stage"
        />
        <div class="stage">
            <slot />
            <FormButton
                class="button"
                @click="$emit('next')"
            >
                {{ button }}
            </FormButton>
        </div>
    </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormButton from "@/components/template/elements/FormButton.vue";
import Popup from "@/components/template/Popup.vue";
import StageDots from "@/components/subscribe/StageDots.vue";

export default defineComponent({
    components: {
        FormButton,
        Popup,
        StageDots
    },
    props: {
        button: {
            type: String,
            default: "Suivant"
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    emits: ["next"]
});
</script>

<style scoped lang="scss">
.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.stage {
    text-align: center;
}
</style>
