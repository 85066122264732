
import { defineComponent } from "vue";

import SubscriptionMail from "@/components/subscribe/stages/SubscriptionMail.vue";
import SubscriptionName from "@/components/subscribe/stages/SubscriptionName.vue";
import SubscriptionPassword from "@/components/subscribe/stages/SubscriptionPassword.vue";
import Welcome from "@/components/subscribe/stages/Welcome.vue";
import PharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        SubscriptionMail,
        SubscriptionName,
        SubscriptionPassword,
        Welcome
    },
    data () {
        return {
            stage: 0,
            name: "",
            mail: "",
            password: ""
        };
    },
    methods: {
        async subscribe (): Promise<void> {
            await PharmacyStore.register({
                name: this.name,
                mail: this.mail,
                password: this.password
            });
            if (await PharmacyStore.isConnected()) {
                this.$router.push({ name: "home" });
            }
        }
    }
});
