
import { defineComponent } from "vue";
import * as Yup from "yup";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import StageLayout from "@/components/subscribe/stages/StageLayout.vue";

export default defineComponent({
    components: {
        ContentSpacer,
        FormInput,
        StageLayout
    },
    emits: ["next", "update:modelValue"],
    data () {
        return {
            rules: Yup.string()
                .required("Vous devez entrer un mot de passe.")
                .min(8, "Votre mot de passe doit contenir au moins 8 caractères")
                .matches(/[A-Za-z]/, "Votre mot de passe doit contenir au moins une lettre")
                .matches(/[0-9]/, "Votre mot de passe doit contenir au moins un chiffre"),
            value: ""
        };
    },
    watch: {
        value () {
            this.$emit("update:modelValue", this.value);
        }
    },
    methods: {
        async checkForm (): Promise<void> {
            const isValid = await (this.$refs.input as typeof FormInput).checkValidity();

            if (isValid) {
                this.$emit("next");
            }
        }
    }
});
