<template>
    <div class="stage-dots">
        <div
            v-for="stageNumber in total"
            :key="stageNumber"
            class="dot"
            :class="{colored:(stage>=stageNumber)}"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        stage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            default: 3
        }
    }
});
</script>

<style scoped lang="scss">
.stage-dots {
    display: flex;
    .dot {
        margin: 0 16px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        box-shadow: var(--color-shadow-soft) 5px 5px 10px,
        var(--color-light-hard) -5px -5px 10px;

        &.colored {
            background: var(--color-primary);
        }
    }
}
</style>
