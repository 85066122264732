
import { defineComponent } from "vue";
import StageLayout from "@/components/subscribe/stages/StageLayout.vue";

export default defineComponent({
    components: {
        StageLayout
    },
    emits: ["next"]
});
